import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {apiGet, getUserJson} from "../utils";
import LinearProgress from "@material-ui/core/LinearProgress";

const userJson = getUserJson();
const scoring = userJson? userJson.league_scoring : {};
const statIdDescriptions = {
    '4': 'Pass Yds',
    '5': 'Pass TD',
    '6': 'Pass Int',
    '9': 'Rush Yds',
    '10': 'Rush TD',
    '12': 'Rec Yds',
    '13': 'Rec TD',
    '15': 'Ret TD',
    '16': 'Two Pts',
    '18': 'Fum Lost',
    '19': 'FG 0-19',
    '20': 'FG 20-29',
    '21': 'FG 30-39',
    '22': 'FG 40-49',
    '23': 'FG 50+',
    '29': 'PAT',
    '57': 'OFR TD'
};
const cellStyle = {padding: 10, textAlign: 'center', border: "thin black solid", fontWeight: "normal"};

export const PlayerStatsDialog = ({open, playerRow, seasonYear, weekNum, handleClose}) => {
    const [playerPerformance, setPlayerPerformance] = useState({});
    const [tableContent, setTableContent] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (playerRow.yahoo_id && seasonYear && weekNum) {
            setLoading(true);
            apiGet(`player/performance/player/${playerRow.yahoo_id}/season/${seasonYear}/week/${weekNum}`, (data) => {
                setPlayerPerformance(data.data ? data.data : {});
                const splitsTableHeaderCells = [];
                const splitsTableStatCells = [];
                const splitsTablePointsCells = [];
                for (const [statId, statValue] of Object.entries(data.data.components)) {
                    splitsTableHeaderCells.push(<th key={`statId${statId}`} style={cellStyle}>{statIdDescriptions[statId]}</th>);
                    splitsTableStatCells.push(<td key={`statVal${statId}`} style={cellStyle}>{statValue}</td>);
                    splitsTablePointsCells.push(<td key={`pointsVal${statId}`} style={cellStyle}>{statValue === 0 ? '' : parseFloat((scoring[statId] * parseFloat(statValue)).toFixed(2))}</td>);
                }
                setTableContent({headerCells: splitsTableHeaderCells, statCells: splitsTableStatCells, pointsCells: splitsTablePointsCells});
                setLoading(false);
            });
        }
    }, [playerRow, seasonYear, weekNum]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
            <DialogTitle id="player-stats-dialog">
                Split Stats
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`${playerRow.first_name} ${playerRow.last_name} - ${playerRow.pos} - ${playerRow.team}`}
                </DialogContentText>
                <DialogContentText>
                    {seasonYear} week {weekNum}
                </DialogContentText>
                <DialogContentText>
                    {playerRow.points} points
                </DialogContentText>
                {loading && <LinearProgress color="secondary"/>}
                {playerPerformance &&
                    <div style={{marginTop: 30, marginBottom: 20}}>
                        <table>
                            <thead>
                            <tr key="statsHeader" className="grid-header">
                                <th style={{backgroundColor: "white"}}/>
                                {tableContent.headerCells}
                            </tr>
                            </thead>
                            <tbody>
                            <tr key="statsRow">
                                <td style={{...cellStyle, textAlign: "left"}}>Stat</td>
                                {tableContent.statCells}
                            </tr>
                            <tr key="pointsRow">
                                <td style={{...cellStyle, textAlign: "left"}}>Points</td>
                                {tableContent.pointsCells}
                            </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </DialogContent>
        </Dialog>
    );
}