import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Typography} from "@material-ui/core";
import {ContractHistoryTable} from "./ContractHistoryTable";
import {PaymentHistoryTable} from "./PaymentHistoryTable";
import {fetchPlayerHistory} from "../containers/History";
import Button from "@material-ui/core/Button";

export const PlayerInfoDialog = ({open, playerRow, handleClose, handleOpenDropDialog, checkDroppable}) => {
    const [playerHistory, setPlayerHistory] = useState(null);
    const [paymentHistory, setPaymentHistory] = useState(null);
    const [showDropButton, setShowDropButton] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPlayerHistory(null);
        setPaymentHistory(null);
        fetchPlayerHistory(playerRow, setLoading, setPlayerHistory, setPaymentHistory);
    }, [playerRow, playerRow.yahoo_id]);

    useEffect(() => {
        setShowDropButton(checkDroppable(playerRow.is_penalty));
    }, [playerRow.is_penalty, checkDroppable]);

    const onClickDropPlayer = () => {
        handleOpenDropDialog(playerRow.yahoo_id);
    };

    return (
        <Dialog open={open && !!playerHistory} onClose={handleClose} maxWidth="md">
            <DialogTitle id="player-info-dialog">
                Player Info
            </DialogTitle>
            <DialogContent>
                {showDropButton &&
                    <Button variant="contained" type="submit" color="primary" style={{float: "right", width: 135}}
                            onClick={onClickDropPlayer}>Drop Player</Button>}
                <DialogContentText>
                    {`${playerRow.first_name} ${playerRow.last_name} - ${playerRow.pos} - ${playerRow.team}`}
                </DialogContentText>
                {loading && <LinearProgress color="secondary"/>}
                {playerHistory &&
                    <div style={{marginTop: 30}}>
                        <Typography variant="h6" color="inherit" display="inline" style={{marginLeft: 10}}>
                            Contract History:
                        </Typography>
                        <ContractHistoryTable playerHistory={playerHistory}/>
                    </div>}
                {paymentHistory &&
                    <div style={{marginTop: 40}}>
                        <Typography variant="h6" color="inherit" display="inline" style={{marginLeft: 10}}>
                            Payment History:
                        </Typography>
                        <PaymentHistoryTable paymentHistory={paymentHistory}/>
                    </div>}
            </DialogContent>
        </Dialog>
    );
}