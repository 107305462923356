import React, {useMemo} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {getUserName} from "../utils";

export const MatchupsGrid = ({rows, loading}) => {

    const columnModels = useMemo(() => {
        const colDefs = {
            rosterColumns: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 150, headerName: 'Winning Manager', field: 'winningManager'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 100, headerName: 'Points', field: 'winnerPoints', type: 'number', headerAlign: 'right', valueFormatter: (v) => v.toFixed(2)},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 100, headerName: 'Points', field: 'loserPoints', type: 'number', headerAlign: 'right', valueFormatter: (v) => v.toFixed(2)},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 150, headerName: 'Losing Manager', field: 'losingManager', headerAlign: 'right', align: 'right'}
            ]
        };

        const calcWidth = (cols) => {
            return cols.reduce((t, c) => t + c.width, 0) + 2;
        };

        const defaultModel = [...colDefs.rosterColumns];

        return {default: {cols: defaultModel, width: calcWidth(defaultModel)}};
    }, []);

    const displayColumns = useMemo(() => {
        return columnModels.default;
    }, [columnModels]);

    const determineCellClass = (params) => {
        let classes = '';
        classes += params.row.isDirtyReitz && params.field.includes('Points') ? 'dirty ' : '';
        classes += params.row.isCleanRandall && params.field.includes('Points') ? 'clean  ' : '';
        classes += params.value === getUserName() ? 'is-self' : '';
        return classes;
    };

    return (
        <div style={{width: displayColumns.width, marginLeft: 10, marginRight: 10}}>
            {loading && <LinearProgress color="secondary"/>}
            <DataGrid columns={displayColumns.cols} rows={rows} hideFooter={true}
                      columnHeaderHeight={30} rowHeight={23} autoHeight
                      getCellClassName={(params) => (determineCellClass(params))}
                      disableRowSelectionOnClick disableMultipleRowSelection disableColumnResize/>
        </div>
    );
}
