import React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {Button} from "@material-ui/core";
import {formatTimestampWithDOW} from "../utils";

let onBiddingClick;
const renderBiddingButton = (params) => {
    const onClick = async () => {
        onBiddingClick(params.row)
    }
    return (
        <Button color="primary" onClick={onClick}>Bidding</Button>
    );
};

const bidGroupListColumns = [
    {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 130, headerName: 'First Name', field: 'first_name'},
    {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 170, headerName: 'Last Name', field: 'last_name'},
    {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 60, headerName: 'Pos', field: 'pos', headerAlign: 'center', align: 'center'},
    {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 70, headerName: 'Team', field: 'team', headerAlign: 'center', align: 'center'},
    {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 200, headerName: 'Game Time', field: 'game_time', headerAlign: 'left', align: 'left', valueGetter: v => v ? formatTimestampWithDOW(v) : ''},
    {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 90, headerName: 'High Bid', field: 'high_bid', type: 'number', headerAlign: 'right'},
    {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 110, headerName: 'High Bidder', field: 'high_bidder', headerAlign: 'center', align: 'center'},
    {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 105, headerName: ' ', field: 'bidding', align: 'center', renderCell: renderBiddingButton}
];

const calcWidth = (cols) => {
    return cols.reduce((t, c) => t + c.width, 0) + 2;
};
const determineRowClass = (params) => {
    return `${params.row.row_number % 2 === 1 ? 'table-row-odd' : 'table-row-even'}`
};

export const BidGroupGrid = ({rows, onBiddingButtonClick}) => {
    onBiddingClick = onBiddingButtonClick;
    return (
        <div style={{width: calcWidth(bidGroupListColumns)}}>
            <DataGrid columns={bidGroupListColumns} rows={rows} density="compact" hideFooter={true} autoHeight
                      getRowClassName={(params) => (determineRowClass(params))}
                      disableSelectionOnClick disableMultipleRowSelection disableColumnResize/>
        </div>
    );
}
